/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red'
  }
}));

interface TiffErrorProps {
  touched: boolean;
  message: string;
}

function TifError({ touched, message }: TiffErrorProps) {
  const classes = useStyles();
  if (!touched) {
    return <div className={classes.error}>&nbsp;</div>;
  }
  if (message) {
    return <div className={classes.error}>{message}</div>;
  }
  return <div>All good</div>;
}

export default TifError;
