/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-curly-brace-presence */

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Paper,
  Theme
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { makeStyles } from '@mui/styles';
import Cookies from 'universal-cookie';
import { useRouter } from 'next/router';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import Link from '../components/atoms/TifLink';
import TifHead from '../components/TifHead/TifHead';
import TifError from '../components/atoms/TifError';
import TifHeaderLine from '../components/atoms/TifHeaderLine';

import { loggedInUserVAR, isLoggedInVAR, accessTokenVAR } from '../util/cache';

const cookies = new Cookies();

// import Cookie from './api/login';

config.autoAddCss = false;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  contentContainerOuter: {
    minHeight: '100vh'
  },
  contentContainerInner: {
    backgroundColor: theme.palette.common.black,
    backdropFilter: 'blur(5px)',
    padding: theme.spacing(4),
    borderRadius: 3
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  tifHeaderLine: {
    marginTop: theme.spacing(4)
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary
  },
  socialButton: {
    marginTop: theme.spacing(1)
  },
  google: {
    backgroundColor: '#db4437',
    color: theme.palette.text.primary
  },
  facebook: {
    backgroundColor: '#3b5998',
    color: theme.palette.text.primary
  },
  apple: {
    backgroundColor: '#333',
    color: theme.palette.text.primary
  }
}));

const query = `mutation login($loginId: String!, $password: String!) {
  action_login(loginId: $loginId, password: $password) {
    refreshToken
    token
    user
  }
}`;

function Login() {
  const router = useRouter();
  const classes = useStyles();
  const [loginError, setLoginError] = useState<{ message: string }>(null);

  const getLoggedIn = async (loginId: string, password: string) => {
    const url = process.env.HASURA_URL;
    try {
      const loginData = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            loginId,
            password
          }
        })
      })
        .then((res) => {
          if (res.status === 404) {
            const error = new Error('authorization failed');
            console.log(error.message); // eslint-disable-line
          }
          return res;
        })
        .then((res) => res.json());
      const { token, refreshToken } = loginData.data.action_login;
      const loggedUser = jwtDecode<JwtPayload>(token);
      cookies.set('refreshToken', refreshToken);
      loggedInUserVAR(loggedUser);
      accessTokenVAR(token);
      isLoggedInVAR(true);
      router.push('/');
      return loginData;
    } catch (err) {
      setLoginError(err);
      return console.log('Login error: ', err); // eslint-disable-line
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(20, 'Too Long!')
      .required('Enter your password'),
    email: Yup.string().email('Invalid email').required('Enter your email')
  });

  return (
    <>
      <TifHead
        title="Login"
        url="/login"
        description="Log into This Is fashion to see the full version of all Media Items in our"
      />
      <div>
        <Grid
          container
          className={classes.contentContainerOuter}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={10}>
            <Paper className={classes.paper}>
              <Container className={classes.contentContainerInner} component="main" maxWidth="xs">
                <Grid container spacing={1} alignContent="center">
                  <Grid item xs={12}>
                    <Typography component="h1" variant="h4" align="center">
                      Login with
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Button
                      fullWidth
                      size="large"
                      className={(classes.socialButton, classes.facebook)}
                      variant="contained"
                      // color="default"
                      onClick={() => {
                        alert('facebook');
                      }}
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      // color="default"
                      className={(classes.socialButton, classes.google)}
                      onClick={() => {
                        alert('google');
                      }}
                    >
                      <FontAwesomeIcon icon={faGoogle} />
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      // color="default"
                      className={(classes.socialButton, classes.apple)}
                      onClick={() => {
                        alert('apple');
                      }}
                    >
                      <FontAwesomeIcon icon={faApple} />
                    </Button>
                  </Grid>
                </Grid>

                <Grid container alignContent="center">
                  <Grid item xs={12}>
                    <TifHeaderLine className={classes.tifHeaderLine}>Or</TifHeaderLine>
                  </Grid>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{ email: '', password: '' }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        getLoggedIn(values.email, values.password);
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur }) => (
                        <Form className={classes.form} autoComplete="on">
                          <TextField
                            value={values.email}
                            variant="standard"
                            margin="dense"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <TifError touched={touched.email} message={errors.email} />
                          <TextField
                            variant="standard"
                            margin="dense"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <TifError touched={touched.password} message={errors.password} />
                          <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                          />
                          {
                            // need to fix
                            loginError && (
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                {loginError.message}
                              </p>
                            )
                          }

                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            // color="default"
                            className={classes.submit}
                          >
                            Sign In
                          </Button>
                          <Grid container spacing={1}>
                            <Grid item xs={12} />
                            <Grid item xs={6}>
                              <Link href="#" variant="body2" color="secondary">
                                Forgot password?
                              </Link>
                            </Grid>
                            <Grid item xs={6}>
                              <Link href="/signup" variant="body2" color="textPrimary">
                                Sign Up
                              </Link>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Login;
