import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  containerOuter: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0px, 1fr) auto minmax(0px, 1fr)',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing(2),

    margin: theme.spacing(4, 0, 1, 0),
    overflow: 'hidden'
  },
  containerLine: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
    allignItems: 'center'
  },
  line: {
    borderBottom: '1px solid'
  }
}));

interface TifHeaderLineProps {
  children: React.ReactNode;
  className?: string;
}

export default function TifHeaderLine({ children }: TifHeaderLineProps) {
  const classes = useStyles();
  return (
    <Box className={classes.containerOuter}>
      <Box className={classes.containerLine}>
        <Box className={classes.line} />
        <Box />
      </Box>
      <Box>
        <Typography variant="h4">{children}</Typography>
      </Box>
      <Box className={classes.containerLine}>
        <Box className={classes.line} />
      </Box>
    </Box>
  );
}
